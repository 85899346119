import { Component, OnInit } from '@angular/core';
import { ReadingJsonService } from '../reading-json.service';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog';
import { DialogTableComponent } from '../dialog-table/dialog-table.component';

@Component({
  selector: 'app-user-editor',
  templateUrl: './user-editor.component.html',
  styleUrls: ['./user-editor.component.scss']
})
export class UserEditorComponent implements OnInit {

  userForm = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
    color: ['', Validators.required],
    season: ['', Validators.required],
    status: [false, Validators.requiredTrue]
  });

  constructor(private formBuilder: FormBuilder, private readingJsonService: ReadingJsonService, private dialog: MatDialog) { }

  ngOnInit(): void { }

  onSubmit() {
    this.readingJsonService.getData().subscribe((res) => {
      console.log(res);
    });

    this.dialog.open(DialogTableComponent, {
      data: [this.userForm.value]
    });
  }


}

<table data-cy="user-data-table" mat-table [dataSource]="dialogData" class="mat-elevation-z8" id="user-data-table">
    <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef> Username </th>
        <td mat-cell *matCellDef="let element"> {{ element.username }} </td>
    </ng-container>

    <ng-container matColumnDef="password">
        <th mat-header-cell *matHeaderCellDef> Password </th>
        <td mat-cell *matCellDef="let element"> {{ element.password }} </td>
    </ng-container>

    <ng-container matColumnDef="color">
        <th mat-header-cell *matHeaderCellDef> Color </th>
        <td mat-cell *matCellDef="let element"> {{ element.color }} </td>
    </ng-container>

    <ng-container matColumnDef="season">
        <th mat-header-cell *matHeaderCellDef> Season </th>
        <td mat-cell *matCellDef="let element"> {{ element.season }} </td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element">
            <mat-icon>done</mat-icon>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
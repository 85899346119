<div class="wrapper">
    <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
        <div class="user-editor">
            <mat-form-field appearance="fill">
                <mat-label>Username</mat-label>
                <input class="custom-input-field" id="username-input" matInput type="text" formControlName="username">
                <mat-icon matSuffix>person</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <input class="custom-input-field" matInput type="password" formControlName="password">
                <mat-label>Password</mat-label>
                <mat-icon matSuffix>password</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Choose an option</mat-label>
                <mat-select id="color-select" data-cy="color" class="custom-input-field" formControlName="color">
                    <mat-option value="red">Red</mat-option>
                    <mat-option value="green">Green</mat-option>
                    <mat-option value="blue">Blue</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-radio-group data-cy="season" formControlName="season">
                <mat-radio-button value="spring">Spring</mat-radio-button>
                <mat-radio-button value="summer">Summer</mat-radio-button>
            </mat-radio-group>

            <mat-checkbox id="checkMe" formControlName="status">Check me!</mat-checkbox>

            <p>Complete the form to enable button.</p>

            <button mat-raised-button class="submit-button" color="primary" type="submit"
                [disabled]="!userForm.valid">Submit</button>
            <button hidden class="submit-button" color="primary">Submit</button>
        </div>
    </form>
</div>
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface UserData {
  username: string;
  password: string;
  color: string;
  season: string;
  status: boolean;
}

@Component({
  selector: 'app-dialog-table',
  templateUrl: './dialog-table.component.html',
  styleUrls: ['./dialog-table.component.scss']
})
export class DialogTableComponent {

  displayedColumns: string[] = ['username', 'password', 'color', 'season', 'status'];

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: Array<UserData>) { }
}
